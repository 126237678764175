<template>
  <v-row no-gutters class="event-details">
    <div>
      <div>
        <v-img max-width="20px" :src="require(`@/assets/warning-square.svg`)" />
      </div>
    </div>
    <v-col v-if="['user-create', 'user-update'].includes(item.event)">
      <div class="mb-2">
        <span>{{ date(item.created_at) }}</span>
        <span>{{ time(item.created_at) }}</span>
      </div>
      <div>
        {{ eventDescription }}
        <status v-if="item.trigger == 'created'" :payment="item" no-icon />
        <div v-if="item.trigger == 'updated'" class="mt-2">
          <attribute v-for="entry of item.data" :key="entry.property" class="mb-2">
            <template #name>{{ $t(entry.property) }}</template>
            <div class="updated-data">
              <span>{{ formattedField(entry, entry.old) }}</span>
              <v-img
                max-width="15px"
                max-height="16px"
                :src="require(`@/assets/arrow-right-black.svg`)"
              />
              <span>{{ formattedField(entry, entry.new) }} </span>
            </div>
          </attribute>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Attribute from "../components/Attribute";
import paymentsMixin from "../../mixins/payments";
import Status from "../components/PaymentStatus";
import * as dayjs from "dayjs";

export default {
  components: {
    Status,
    Attribute,
  },
  mixins: [paymentsMixin],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    eventDescription: function () {
      if (this.item.trigger == "updated") {
        return `${this.$t("events-.updated-payment")}:`;
      }
      return this.$t("events-.new-payment");
    },
  },
  created: async function () {},
  methods: {
    date: function (date) {
      return dayjs(date).format("D MMM, YYYY").toLowerCase();
    },
    time: function (date) {
      return dayjs(date).format("HH:mm");
    },
    formattedField: function (entry, value) {
      if (entry.property == "amount") {
        return `${this.$options.filters.money(value)} USD`;
      }
      if (entry.property == "card_number") {
        return this.formatCardNumber(value);
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.row.event-details {
  gap: 8px;
  > div:first-child {
    > div {
      padding: 10px;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.8)
        ),
        #eacb00;
      border-radius: 20px;
      margin-left: 8px;
    }
  }
  > div:nth-child(2) {
    > div:first-child {
      padding-left: 15px;
      font-size: 13px;
      span:nth-child(2) {
        color: #4e4b66;
        margin-left: 8px;
      }
    }
    > div:nth-child(2) {
      background: #f6f9f6;
      padding: 16px;
      border-radius: 0px 16px 16px 16px;
      .status {
        display: inline-block;
        font-size: 16px;
      }
      .updated-data {
        display: flex;
        span {
          display: block;
          flex: 1 1;
          &:last-child {
            font-weight: 700;
            text-align: right;
          }
        }
        .v-image {
          margin-top: 3px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
