<template>
  <confirm-dialog :width="700" hide-actions>
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small text color="primary" class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-img max-width="22px" :src="require(`@/assets/time-circle.svg`)" />
          {{ $t("see-historical") }}
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("historical") }}
    </template>
    <template #actions>&nbsp;</template>
    <v-row no-gutters>
      <v-col v-for="event of events" :key="event.id" cols="12">
        <event :item="event" />
      </v-col>
    </v-row>
  </confirm-dialog>
</template>

<script>
import Event from "./ScheduledEvent";
import ConfirmDialog from "../components/ConfirmDialog";
import payments from "../../requests/payments";

export default {
  components: {
    ConfirmDialog,
    Event,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    events: [],
  }),
  created: async function () {
    this.events = await payments.scheduleEvents(this.item.id);
    console.log(this.events);
  },
  methods: {},
};
</script>
