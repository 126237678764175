<template>
  <v-container v-if="payment && user" fluid class="py-0">
    <heading>
      <h2>{{ $t("payments-.details") }}</h2>
      <p>{{ $t("payments-.details-sub-heading") }}</p>
      <template #actions>
        <v-btn
          v-if="isClinicDependant(user.level)"
          color="primary"
          elevation="0"
          @click.prevent="$router.push({ name: 'payments-new' })"
        >
          <v-img max-width="22px" :src="require(`@/assets/plus.svg`)" class="mr-2" />
          {{ $t("payments-.new") }}
        </v-btn>
      </template>
    </heading>
    <split-content>
      <template #auxiliar>
        <v-row>
          <v-col>
            <v-text-field
              dense
              hide-details
              v-model="search"
              outlined
              :label="$t('search')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="simple-search-results">
            <div v-for="(entry, date) in payments" :key="date">
              <h5>
                {{
                  `${
                    today() == fromDate(date).format("YYYY-MM-DD")
                      ? `${$t("today")},`
                      : ""
                  } ${fromDate(date).format("MMM D")}`
                }}
              </h5>
              <v-list-item-group v-model="selectedItem">
                <v-list-item
                  v-for="item in entry.items"
                  :key="item.id"
                  :value="item.payment.id"
                >
                  <v-list-item-content>
                    <div>
                      {{
                        `${item.payment.patient.name} ${item.payment.patient.last_name}`
                      }}
                    </div>
                    <status :payment="item" no-text />
                    <div>{{ item.amount | money }} USD</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-col>
        </v-row>
      </template>
      <v-container fluid>
        <v-row>
          <v-col>
            <h4 class="fg-black">{{ $t("patient") }}</h4>
          </v-col>
          <v-col cols="3" class="svg-buttons small-buttons text-right">
            <v-btn
              v-if="isCancellable(payment) && isClinicDependant(user.level)"
              small
              text
              class="fg-blue"
              @click="$router.push({ name: 'payments-edit', params: { id: payment.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
              {{ $t("edit") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="4">
            <attribute>
              <template #name>{{ $t("fullname") }}</template>
              {{ `${payment.patient.name} ${payment.patient.last_name}` }}
            </attribute>
          </v-col>
          <v-col cols="3" md="2">
            <attribute>
              <template #name>{{ $t("prn") }}</template>
              {{ payment.patient.prn }}
            </attribute>
          </v-col>
          <v-col cols="5">
            <attribute>
              <template #name>{{ $t("contact") }}</template>
              <v-row>
                <v-col class="pb-0 mb-0">
                  {{ payment.patient.phone_number }}
                </v-col>
                <v-col cols="7" class="pb-0 mb-0">
                  {{ payment.patient.email }}
                </v-col>
              </v-row>
            </attribute>
          </v-col>
          <v-col cols="12">
            <attribute>
              <template #name>{{ $t("address") }}</template>
              {{ payment.patient.formatted_address }}
            </attribute>
          </v-col>
        </v-row>
        <v-row v-if="payment.documents.length > 0" class="bottom-lined">
          <v-col cols="6">
            <h4 class="fg-black">{{ $t("documents") }}</h4>
          </v-col>
          <v-col cols="6" class="svg-buttons small-buttons text-right">
            <v-btn
              v-if="isCancellable(payment) && isClinicDependant(user.level)"
              small
              text
              class="fg-blue"
              @click="$router.push({ name: 'payments-edit', params: { id: payment.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
              {{ $t("edit") }}
            </v-btn>
          </v-col>
          <v-col v-for="doc in payment.documents" :key="doc.id" cols="3">
            <attribute>
              <template #name>{{ `PDF - 1.3 MB` }}</template>
              <v-row>
                <v-col cols="12" class="pb-0">{{ doc.original_filename }}</v-col>
                <v-col cols="12" class="py-0">
                  <v-btn
                    depressed
                    text
                    plain
                    color="primary"
                    @click.prevent="downloadFile(doc)"
                  >
                    {{ $t("download") }}
                  </v-btn>
                </v-col>
              </v-row>
            </attribute>
          </v-col>
        </v-row>
        <v-row class="bottom-lined">
          <v-col cols="8">
            <h4 class="fg-black">
              {{ `${$t("payment-plan")} - ${$t(payment.type)}` }}
            </h4>
          </v-col>
          <v-col cols="4" class="svg-buttons small-buttons text-right">
            <remove-dialog
              v-if="isCancellable(payment)"
              :item="payment"
              @removed="$router.push({ name: 'payments' })"
            >
              <template #activator="{ on, attrs }">
                <v-btn small text class="fg-red" v-bind="attrs" v-on="on">
                  <v-img max-width="22px" :src="require(`@/assets/delete-hover.svg`)" />
                  {{ $t("cancel-payment") }}
                </v-btn>
              </template>
            </remove-dialog>
          </v-col>
          <v-col v-for="(schedule, i) of payment.schedules" :key="schedule.id" cols="12">
            <v-row>
              <v-col cols="12">
                <h5 class="fg-black fz-18 d-inline mr-2">
                  {{ `${$t("payment")} #${i + 1}` }}
                </h5>
                <div class="d-inline-block">
                  <status :payment="schedule" no-icon />
                </div>
              </v-col>
              <v-col>
                <attribute>
                  <template #name>{{ $t("amount") }}</template>
                  {{ schedule.amount | money }} USD
                </attribute>
              </v-col>
              <v-col>
                <attribute>
                  <template #name>{{ $t("date") }}</template>
                  {{ fromDate(schedule.scheduled_at).format("D MMM, YYYY") }}
                </attribute>
              </v-col>
              <v-col>
                <attribute>
                  <template #name>{{ $t("payment-method") }}</template>
                  {{ schedule.payment_method ? $t(schedule.payment_method.slug) : "-" }}
                </attribute>
              </v-col>
              <v-col cols="5" class="svg-buttons small-buttons text-right">
                <v-btn
                  v-if="schedule.status == 'pending' && isClinicDependant(user.level)"
                  small
                  text
                  class="fg-blue"
                  @click="
                    $router.push({ name: 'payments-edit', params: { id: payment.id } })
                  "
                >
                  <v-img max-width="22px" :src="require(`@/assets/edit-hover.svg`)" />
                  {{ $t("edit") }}
                </v-btn>
                <scheduled-events-dialog :item="schedule" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </split-content>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Status from "../components/PaymentStatus";
import Heading from "../components/Heading";
import Attribute from "../components/Attribute";
import usersMixin from "../../mixins/users";
import paymentsMixin from "../../mixins/payments";
import SplitContent from "../components/SplitView";
import payments from "../../requests/payments";
import RemoveDialog from "./RemoveDialog";
import ScheduledEventsDialog from "./ScheduledEventsDialog";
import * as dayjs from "dayjs";

export default {
  mixins: [usersMixin, paymentsMixin],
  components: {
    Heading,
    Attribute,
    SplitContent,
    RemoveDialog,
    ScheduledEventsDialog,
    Status,
  },
  data: () => ({
    search: "",
    selectedItem: null,
    user: null,
    payments: null,
    payment: null,
    timeout: null,
  }),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  watch: {
    search: async function () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        function () {
          this.loadItems();
        }.bind(this),
        1000
      );
    },
    selectedItem: async function () {
      if (this.selectedItem == null) {
        return;
      }
      if (this.selectedItem == this.payment.id) {
        return;
      }
      this.payment = await payments.details(this.selectedItem);
    },
  },
  created: async function () {
    if (!this.$route.params.id) {
      this.$router.push({ name: "payments" });
    }

    this.payment = await payments.details(this.$route.params.id);
    this.selectedItem = this.payment.id;

    this.user = await this.getUserInfo;

    await this.loadItems();
  },
  methods: {
    fromDate: function (date) {
      return dayjs(date);
    },
    loadItems: async function () {
      this.payments = (
        await payments.schedules({
          keyword: this.search,
          length: 10,
          from: this.search == "" ? dayjs().format("YYYY-MM-DD") : "",
        })
      ).items;
    },
    downloadFile: async function (doc) {
      try {
        await payments.downloadDocumentById(this.payment.id, doc);
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    today: function () {
      return dayjs().format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped></style>
