<template>
  <confirm-dialog @confirmed="removeItem">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small icon color="error" class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-img max-width="22px" :src="require(`@/assets/delete.svg`)" />
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("payments-.remove") }}
    </template>
    {{ $t("payments-.remove_question", { "clinic-name": "asdasd" }) }}
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import payments from "../../requests/payments";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  methods: {
    removeItem: async function () {
      try {
        payments.remove(this.item.id);
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
        this.$emit("removed");
      } catch (e) {
        this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>
